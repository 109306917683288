import React from 'react'
import { ComponentTypes, Components } from './components'
import { NameComponent } from './components/name'
import { TitleComponent } from './components/title'
import { getLogo, getWebUrl } from './utils'
import { AddressComponent } from './components/address'
import { BrandLogos } from './components/brandLogos'
import { SignatureData } from './signature.d'
export * from './signature.d'

export const baseTextStyle = {
  display: 'inline-block',
  fontFamily: 'Century Gothic, Helvetica Neue, Helvetica, sans-serif',
  fontSize: '12pt',
  lineHeight: '12.8pt',
  color: '#808080',
}

const Signature: React.FC<SignatureData> = (data) => {
  return (
    <>
      <table style={{ border: '0px', width: 'auto' }} ref={data.signatureRef}>
        <tbody style={{ border: 'none' }}>
          <tr style={{ border: 'none' }}>
            <td
              style={{
                border: 'none',
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <table style={{ border: '0px', width: 'auto' }}>
                <tbody style={{ border: 'none' }}>
                  <tr style={{ border: 'none' }}>
                    <td
                      style={{
                        // border: 'none',
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingTop: 0,
                        paddingBottom: 0,
                        borderRight: '1px solid rgba(128, 128, 128, 0.47)',
                      }}
                    >
                      <img src={getLogo(data.type)} width="150" />
                    </td>
                    <td
                      style={{
                        ...baseTextStyle,
                        border: 'none',
                        paddingRight: 5,
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingLeft: 18,
                      }}
                    >
                      <NameComponent {...data} />
                      <br />
                      <TitleComponent {...data} />
                      <br />
                      <br />
                      <Components {...data} />
                      {data.fields.some((o) => o.value === 'address') &&
                        data.address && (
                          <>
                            <br />
                            <AddressComponent {...data} />
                          </>
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr style={{ border: 'none' }}>
            <td
              style={{
                border: 'none',
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 10,
                paddingBottom: 0,
              }}
            >
              <BrandLogos {...data} />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Signature
