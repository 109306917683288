import React, { useEffect, useRef, useState } from 'react'
import Button from '../button/button'
import { PromptResponseType, PromptResponseValue } from '../../types'
import Autocomplete from 'react-autocomplete'
import locationData from '../../../email/signature/addresses.json'

export interface TypeheadResponseType extends PromptResponseType {
  type: 'select'
  conditional?: boolean
  options: PromptResponseValue[]
  locations: any[]
}

type TypeheadResponseWrapperProps<T> = {
  response: TypeheadResponseType
  context: T
  submitResponse: (response: PromptResponseValue) => void
  locations: any[]
}

type TypeheadResponseProps<T> = TypeheadResponseWrapperProps<T> & {
  options: any[]
  locations: any[]
}

type locationProps = {
  branchid: string
  address: string
  city: string
  zipcode: string
  location: string
  state: string
}

function TypeheadResponse<T>({
  response,
  context,
  submitResponse,
  options,
}: TypeheadResponseProps<T>) {
  const firstOption = useRef<HTMLDivElement>()
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState<locationProps[]>([])

  useEffect(() => {
    if (firstOption.current) {
      firstOption.current.focus()
    }
  }, [firstOption])

  const handleInputChange = (event) => {
    const { value } = event.target
    setValue(value)

    // Filter items based on multiple values
    const filteredItems = options.filter((item) => {
      return (
        item.branchid.toLowerCase().includes(value.toLowerCase()) ||
        item.address.toLowerCase().includes(value.toLowerCase()) ||
        item.city.toLowerCase().includes(value.toLowerCase()) ||
        item.state?.toLowerCase().includes(value.toLowerCase()) ||
        item.zipcode.toLowerCase().includes(value.toLowerCase())
      )
    })

    setSuggestions(filteredItems)
  }

  const handleSelect = (value: string) => {
    const selected = options.find((item) => item.branchid === value)
    if (selected) {
      const formattedAddress = [
        `${selected.address}`,
        `${selected.city}, ${selected.state} ${selected.zipcode}`,
      ]
      submitResponse({
        display: selected.location,
        value: formattedAddress,
      })
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        position: 'relative',
        marginTop: 20,
      }}
    >
      {/* @ts-ignore */}
      <Autocomplete
        items={suggestions}
        getItemValue={(item) => item.branchid}
        value={value}
        onChange={handleInputChange}
        onSelect={handleSelect}
        renderInput={(props) => (
          <input
            tabIndex={1}
            autoFocus
            type="text"
            style={{ maxWidth: '300px', margin: 10 }}
            {...props}
          />
        )}
        menuStyle={{
          borderRadius: '5px',
          border: '1px solid #999',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '2px 0',
          fontSize: '90%',
          position: 'fixed',
          marginLeft: '-10%',
          overflow: 'auto',
          minWidth: '500px',
          maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
        }}
        inputProps={{
          placeholder: 'Search location...',
          onFocus: () => {
            setSuggestions(options)
          },
        }}
        renderItem={(item, highlighted) => (
          <div
            key={item.branchid}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: highlighted ? '#eee' : 'transparent',
              borderBottom: 'solid 1px #eee',
              padding: '5px 0px',
            }}
          >
            <p
              style={{
                margin: '0px 15px 0 15px',
                minWidth: '40px',
                textAlign: 'center',
                fontSize: 28,
              }}
            >
              {item.branchid}
            </p>
            <div>
              <p style={{ margin: 0, padding: 0, fontWeight: 700 }}>
                {item.location}
              </p>
              <p style={{ margin: 0, padding: 0 }}>{item.address}</p>
              <p style={{ margin: 0, padding: 0 }}>
                {' '}
                {`${item.city}, ${item.state} ${item.zipcode}`}
              </p>
            </div>
          </div>
        )}
      />
    </div>
  )
}

//There is currently too much specific logic in the TypeaheadResponse component to be re-usable. Move some of it here, but it should really be defined in prompts and processed by a custom (see rules) parser
function TypeheadResponseWrapper<T>({
  response,
  context,
  submitResponse,
  locations,
}: TypeheadResponseWrapperProps<T>) {
  const options: locationProps[] = locations.filter(
    (item) =>
      item.type ===
      (context['type'] == 'LendDirect' ? 'CURO Corporate' : context['type'])
  )
  return (
    <TypeheadResponse
      response={response}
      context={context}
      submitResponse={submitResponse}
      options={options}
    />
  )
}

export default TypeheadResponseWrapper
