import React from 'react'
import { SignatureComponent } from '.'

const imgLogo = {
  'Cash Money': {
    image: '/images/',
  },
}
export const BrandLogos: SignatureComponent = ({ type }) => {
  return <img src={`/images/${type} Horizontal.png`} width={500} />
}
