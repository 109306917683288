import React from 'react'
import { SignatureComponent } from '.'
import { baseTextStyle } from '..'

export const AddressComponent: SignatureComponent = (data) => {
  const { address } = data

  return (
    <span style={{ ...baseTextStyle }}>
      {address && address[0]}
      <br />
      {address && address[1]}
    </span>
  )
}
