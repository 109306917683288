import { useEffect, useState } from 'react'
import Email from '../../modules/email'
import { keyframes } from '@emotion/react'
import { Box, Flex } from 'theme-ui'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
const EmailPage = () => {
  const [locations, setLocations] = useState(null)
  const fetchLocations = async () => {
    {
      const API_KEY = '4b8b607c79dcf1fcc5b209581a9c6615'
      const ACCOUNT_ID = 'me'
      const filter = {
        $or: [
          { name: { $eq: 'Cash Money' } },
          { name: { $eq: 'Heights Finance' } },
          { name: { $eq: '1st Heritage Credit' } },
          { name: { $eq: 'Quick Credit' } },
          { name: { $eq: 'Covington Credit' } },
          { name: { $eq: 'Southern Finance' } },
        ],
      }

      async function fetchAllLocations() {
        let locations = []
        let pageToken = true

        while (pageToken) {
          const YEXT_ENDPOINT = encodeURI(
            `https://cdn.yextapis.com/v2/accounts/${ACCOUNT_ID}/entities?api_key=${API_KEY}&v=20211001&limit=50&filter=${JSON.stringify(
              filter
            )}${typeof pageToken === 'string' ? `&pageToken=${pageToken}` : ''}`
          )

          // Log the constructed endpoint URL to verify it’s correct
          // console.log('Requesting Yext API with endpoint:', YEXT_ENDPOINT)

          try {
            const response = await fetch(YEXT_ENDPOINT)
            // Log response status to check if it was successful
            // console.log('API response status:', response.status)

            if (!response.ok) {
              console.error(
                'Error in response:',
                response.status,
                response.statusText
              )
              return {
                status: 500,
                headers: {},
                props: {},
              }
            }

            const data = await response.json()
            // Log response data to inspect structure and content
            // console.log('API response data:', data)

            locations = locations.concat(data.response.entities || [])
            // Log pageToken to see if there’s another page to fetch
            pageToken = data.response.pageToken || false
            // console.log('Next pageToken:', pageToken)
          } catch (error) {
            console.error('Fetch error:', error)
            return {
              status: 500,
              headers: {},
              props: {},
            }
          }
        }

        return locations
      }

      try {
        const allLocations: any = await fetchAllLocations()
        // Log the total number of locations retrieved
        // console.log('Total locations retrieved:', allLocations.length)

        let formattedLocations = allLocations.map((location) => ({
          branchid: location.meta.id,
          type: location.name || 'Unknown Type',
          location: location.name || 'Unknown Location',
          address: `${location.address?.line1}${
            location.address?.line2 ? ', ' + location.address.line2 : ''
          }`,
          city: location.address?.city || 'Unknown City',
          state: location.address?.region || 'Unknown State',
          zipcode: location.address?.postalCode || 'Unknown Zipcode',
        }))

        formattedLocations = formattedLocations.concat([
          {
            branchid: '1',
            type: 'Attain Finance',
            location: 'Canada business address',
            address: '6-2200 Queen St E',
            city: 'Brampton',
            state: 'ON',
            zipcode: 'L6S 4G9',
          },
          {
            branchid: '2',
            type: 'Attain Finance',
            location: 'Canada mailing address',
            address: '4-2880 Queen St E, Ste 316',
            city: 'Brampton',
            state: 'ON',
            zipcode: 'L6S 6E8',
          },
          {
            branchid: '3',
            type: 'Attain Finance',
            location: 'Greenville Office',
            address: '101 N Main St, #600',
            city: 'Greenville',
            state: 'SC',
            zipcode: '29601',
          },
          // Additional static entries here...
        ])

        // console.log('Formatted locations:', formattedLocations)

        return formattedLocations
      } catch (error: any) {
        console.error('Error in getServerData:', error.message)
        return {
          error: error.message,
        }
      }
    }
  }
  useEffect(() => {
    ;(async () => {
      const fetchedLocations: any = await fetchLocations()
      setLocations(fetchedLocations)
    })()
  }, [])
  return locations ? (
    <Email locations={locations} />
  ) : (
    <Flex
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          border: `${40 * 0.1}px solid`,
          borderColor: `black black black transparent`,
          borderRadius: '50%',
          animation: `${spin} 1s linear infinite`,
        }}
      />
    </Flex>
  )
}
export default EmailPage

// export async function getServerData()
