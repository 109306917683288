import React from 'react'
import { SignatureComponent } from '.'
import { baseTextStyle } from '..'

export const NameComponent: SignatureComponent = ({ name }) => {
  return (
    <span
      style={{
        ...baseTextStyle,
        fontWeight: 'bold',
        fontSize: '12pt',
      }}
    >
      {name}
    </span>
  )
}
